/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Modal from '@material-ui/core/Modal';

/**
 * Internal dependencies.
 */
import WidgetVideo from 'components/widget-video/widget-video';
import ModalDeleteMedia from 'components/modal-delete-media/modal-delete-media';
import { getIconByType, requireAuthToken, getAppMetadataValue, getProfileData, requireUserID } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 42px',
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalField: {
		marginTop: '-6px',
		marginBottom: '13px',
		'& input': {
			fontSize: '18px',
			fontWeight: '500',
			letterSpacing: '0.5px',
			height: '44px',
			border: 'none',
			background: '#F0F0F3',
			fontFamily: 'Poppins, sans-serif',
			color: '#323338',
			'&::-webkit-input-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
			'&::-moz-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
			'&:-moz-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
			'&:-ms-input-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
		}
	},
	modalCircle: {
		width: '200px',
		height: '200px',
		borderWidth: '2px',
		[theme.breakpoints.down('xs')]: {
			margin: 'auto'
		},
		'& .MuiButtonBase-root': {
			minWidth: '134px',
			fontSize: '11px',
			borderRadius: '9px',
			padding: '8px 10px',
			marginTop: '3px',
		}
	},
	modalSubtitle: {
		fontSize: '16px',
		fontWeight: '500',
		color: '#808191',
		marginBottom: '11px'
	},
	modalHint: {
		marginTop: '14px',
		fontSize: '16px',
		fontWeight: '500',
		color: '#808191',
		'& span': {
			display: 'block',
			color: '#323338',
			marginBottom: '17px',
		},
		'& i': {
			marginRight: '8px',
		}
	},
	modalActions: {
		margin: '16px -30px 0',
		padding: '27px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	},
	modalLink: {
		padding: '0',
		margin: '21px 0 0',
		color: '#00CCEE',
		fontSize: '18px',
		letterSpacing: '0.5px',
	},
	errorMessage: {
		color: '#f50057'
	}
}));

const ModalMediaEdit = ( { onClose, modifier, title, url, thumbnail, type } ) => {
	const classes = useStyles();
	const [ disabled, setDisabled ] = useState(false);
	const [ openDeleteModal, setOpenDeleteModal ] = useState(false);
	const [ errorMessage, setErrorMessage ] = useState("");

	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose}>
					<i className="ico-x"></i>
				</button>

				<Typography 
					variant="h4" 
					component="h4"
					className={classes.modalTitle}
				>Editar archivo</Typography>
			</Box>

			<Box className={classes.modalBody}>
				<WidgetVideo 
					image={url}
					thumbnail={thumbnail}
					type={type}
				/>

				<Input defaultValue={title} id="name-field" className={classes.modalField} disabled={disabled || type === "text/pdf"}/>

				<Typography 
					variant="h4" 
					component="h4"
					className={classes.modalHint}
				>
					{getIconByType(type)} {type} {/* · 8,2 MB TODO implement size*/}
				</Typography>

				<Button 
					variant="text" 
					className={classes.modalLink}
					onClick={(e) => { setOpenDeleteModal(!openDeleteModal) }}
				>Eliminar archivo</Button>
			</Box>
			<Typography variant="subtitle1" className={classes.errorMessage}>{errorMessage}</Typography>

			<Box className={classes.modalActions}>
				<Button 
					variant="outlined" 
					color="secondary"
					className={classes.modalButton}
					onClick={onClose}
				>Cancelar</Button>
				
				<Button 
					variant="contained" 
					color="secondary"
					className={classes.modalButton}
					disabled={disabled}
					onClick={(e) => { 
						setDisabled(true)
						setErrorMessage("");
						submitChanges();

						async function submitChanges() {
							const authToken = await requireAuthToken(false, true);

							const newTitle = document.getElementById("name-field").value;
							const regex = new RegExp('[^A-Za-z0-9.]');
							if (!newTitle) {
								setErrorMessage("El nombre de fichero no puede ser vacío.");
								setDisabled(false);
								return;
							} else if (newTitle === title) {
								setErrorMessage("El nombre de fichero es el mismo que el actual");
								setDisabled(false);
								return;
							} else if (regex.test(newTitle)) {
								setErrorMessage("El nombre de fichero no puede contener símbolos, tildes ni espacios.");
								setDisabled(false);
								return;
							}
							

							let collection = "_NoCollection";

							try {
								collection = getAppMetadataValue(await getProfileData(requireUserID(true)), "Creator");
							} catch (error) {
								console.error(error);
								setErrorMessage("Error retrieving userID");
								return;
							}
				
							const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/media/rename`, {
								method: "POST",
								body: JSON.stringify({
									Collection: collection,
									FileName: title,
									NewFileName: newTitle
								}),
								headers: {
									"Content-type": "application/json; charset=UTF-8",
									"Authorization": `Bearer ${authToken}`
								}
							});

							if (!response.ok) {
								setDisabled(false);
								const error = `HTTP error. status: ${response.status}`;
								setErrorMessage(error);
								throw new Error(error);
							}
							
							const data = await response.json();
							
							//console.log(data);
							
							if ("Error" in data) {
								setDisabled(false);
								const error = `Error editando media: ${data.Error.Message}`;
								setErrorMessage(error);
								throw new Error(error);
							}

							window.location.reload();

							onClose();
						}

					 }}
				>Guardar</Button>
			</Box>

			<Modal
				open={openDeleteModal}
				onClose={(e) => { setOpenDeleteModal(false) }}
			>
				<div><ModalDeleteMedia title={title} type={type} onClose={(e) => { setOpenDeleteModal(false) }}/></div>
			</Modal>

		</Box>
	);
};

export default ModalMediaEdit;