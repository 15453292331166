/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

/**
 * Internal dependencies.
 */
import User from 'components/user/user';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 49px',
	},
	modalBody: {
		position: 'relative',
		padding: '0 10px',
		maxHeight: '392px',
		overflowY: 'auto',
		overflowX: 'hidden',
		[theme.breakpoints.down('xs')]: {
			padding: '0',
			maxHeight: '193px',
		},
		'&::-webkit-scrollbar' : {
			width: '6px'
		},
		'&::-webkit-scrollbar-track' : {
			width: '6px',
			background: '#E6E9EF',
			borderRadius: '20px',
		},
		'&::-webkit-scrollbar-thumb' : {
			borderRadius: '20px',
			background: '#808191',
		}
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalActions: {
		margin: '35px -30px 0',
		padding: '27px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}

	}
}));

const users = [
	{
		avatar: require('assets/images/av5.jpg'),
		name: "Tú",
		checked: false,
		admin: true
	},
	{
		avatar: require('assets/images/av2.jpg'),
		name: "Susana Suárez Sánchez",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av3.jpg'),
		name: "Adrián Álvarez Pérez",
		checked: true,
		admin: false
	},
	{
		avatar: require('assets/images/av1.jpg'),
		name: "Miguel Martínez González",
		checked: false,
		admin: false
	},
	{
		avatar: "",
		name: "Sara García",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av4.jpg'),
		name: "Santiago Rodríguez",
		checked: false,
		admin: false
	},
	{
		avatar: "",
		name: "Marina Ortega",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av2.jpg'),
		name: "Susana Suárez Sánchez",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av3.jpg'),
		name: "Adrián Álvarez Pérez",
		checked: true,
		admin: false
	},
	{
		avatar: require('assets/images/av1.jpg'),
		name: "Miguel Martínez González",
		checked: false,
		admin: false
	},
	{
		avatar: "",
		name: "Sara García",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av4.jpg'),
		name: "Santiago Rodríguez",
		checked: false,
		admin: false
	},
	{
		avatar: "",
		name: "Marina Ortega",
		checked: false,
		admin: false
	}
]

const ModalInvite = ( { onClose, modifier } ) => {
	const classes = useStyles();

	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose}>
					<i className="ico-x"></i>
				</button>

				<Typography 
					variant="h4" 
					component="h4"
					className={classes.modalTitle}
				>Invitar usuarios</Typography>
			</Box>

			<Box className={classes.modalBody}>
				{users?.map((user, index) => {
					return (
						<User
							key={index}
							name={user.name}
							avatar={user.avatar}
							checked={user.checked}
							admin={user.admin}
						/>
					) 
				})}
			</Box>

			<Box className={classes.modalActions}>
				<Button 
					variant="outlined" 
					color="secondary"
					className={classes.modalButton}
					onClick={onClose}
				>Atrás</Button>
				
				<Button 
					variant="contained" 
					color="secondary"
					className={classes.modalButton}
					onClick={onClose}
				>Crear máster</Button>
			</Box>
		</Box>
	);
};

export default ModalInvite;