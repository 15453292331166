/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Modal from '@mui/material/Modal';
import Skeleton from '@mui/material/Skeleton';


/**
 * Internal dependencies.
 */
import ModalEditUser from 'components/modal-edit-user/modal-edit-user';

const useStyles = makeStyles(theme => createStyles({
	article: {
		position: 'relative',
		marginBottom: '26px',
		background: '#fff',
		boxShadow: '-8px -16px 24px rgba(227, 230, 236, 0.5), 16px 8px 24px rgba(227, 230, 236, 0.5)',
		borderRadius: '16px',
		display: 'flex',
		alignItems: 'center',
		padding: '9px 40px',
		[theme.breakpoints.down('md')]: {
			padding: '7px 20px'
		},
		[theme.breakpoints.down('xs')]: {
			display: 'block',
			padding: '0 20px 0 50px',
			boxShadow: 'none',
			background: 'none',
			marginBottom: '20px',
		}
	},
	articleActions: {
		position: 'absolute',
		top: '50%',
		right: '40px',
		transform: 'translateY(-50%)',
		[theme.breakpoints.down('md')]: {
			right: '20px',
		},
		[theme.breakpoints.down('xs')]: {
			transform: 'translateY(0)',
			top: '-4px',
			right: '0',
		},
		'& button': {
			fontSize: '18px',
			minWidth: '180px',
			color: '#00CCEE',
			letterSpacing: '1px',
			background: 'none !important',
			borderRadius: '16px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '0',
				minWidth: '0',
				padding: '0',
			},
			'&:hover': {
				boxShadow: '-4px -4px 16px rgba(227, 230, 236, 0.5), 8px 4px 16px rgba(227, 230, 236, 0.5)'
			},
			'& i': {
				display: 'none',
				[theme.breakpoints.down('sm')]: {
					display: 'inline-block',
				}
			}
		}
	},
	articleId: {
		color: '#808191',
		fontWeight: '600',
		fontSize: '24px',
		width: '59px',
		marginRight: '78px',
		textAlign: 'right',
		[theme.breakpoints.down('md')]: {
			width: '30px',
			marginRight: '20px',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '20px',
		},
		[theme.breakpoints.down('xs')]: {
			width: 'initial',
			textAlign: 'left',
			fontSize: '12px',
			marginBottom: '5px',
		},
		'& span': {
			display: 'none',
			color: '#323338',
			[theme.breakpoints.down('xs')]: {
				display: 'inline-block',
				fontSize: '12px',
				fontWeight: '700',
			},
			'& i': {
				width: '10px',
				height: '10px',
			}
		}
	},
	articleName: {
		width: '401px',
		marginRight: '9px',
		[theme.breakpoints.down('md')]: {
			width: '250px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '180px',
			fontSize: '12px',
			marginBottom: '5px',
		},
		'& span': {
			display: 'none',
			[theme.breakpoints.down('xs')]: {
				display: 'block',
				fontSize: '12px',
				fontWeight: '700',
			}
		}
	},
	articleEmail: {
		width: '463px',
		[theme.breakpoints.down('md')]: {
			width: '350px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '240px',
			fontSize: '12px'
		},
		[theme.breakpoints.down('xs')]: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		'& span': {
			display: 'none',
			[theme.breakpoints.down('xs')]: {
				display: 'block',
				fontSize: '12px',
				fontWeight: '700',
			}
		}
	},
	articleAvatar: {
		border: '2px solid #00CCEE',
		width: '64px',
		height: '64px',
		fontSize: '20px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px',
			width: '40px',
			height: '40px'
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
			width: '26px',
			height: '26px',
			position: 'absolute',
			top: '0',
			left: '0',
		}
	},
	skeletonStyle: {
		minHeight: '2em !important',
		marginRight: '2em'
	},
}));


const ArticleAlt = ( { id, avatar, name, email, skeleton } ) => {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);

	if (skeleton)
	return (
		<Box className={classes.article}>
		<Skeleton animation='wave' variant="circular" className={classes.articleName}>
		{!avatar.length ? (
			<Avatar className={classes.articleAvatar}>
				{name.split(' ')[0][0].toUpperCase() + name.split(' ')[1][0].toUpperCase()}
			</Avatar>
		) : (
			<Avatar
				className={classes.articleAvatar}
				alt={name}
				src={avatar}
			/>
		)}
		</Skeleton>
			<Typography 
				variant="h5" 
				component="h5"
				className={classes.articleId}
			>
				<span>ID</span> {/* id TODO make pretty*/} 
			</Typography>

			<Skeleton animation='wave' variant="text" className={classes.skeletonStyle} /* style={{width: "5em"}} */>
			<Typography 
				variant="h5" 
				component="h5"
				className={classes.articleName}
			>
				<span>Nombre de usuario</span>

				{name}
			</Typography>
			</Skeleton>

			<Skeleton animation='wave' variant="text" className={classes.skeletonStyle}>
			<Typography 
				variant="h5" 
				component="h5"
				className={classes.articleEmail}
			>
				<span>Correo electrónico</span>

				{email}
			</Typography>
			</Skeleton>

			<Box className={classes.articleActions}>
				<Skeleton animation='wave' variant="rectangular" >
				<Button 
					variant="text" 
					className={classes.articleButton}
					onClick={(e) => { setOpenModal(!openModal) }}
				>
					Editar perfil

					<i className="ico-pen"></i>
				</Button>
				</Skeleton>
			</Box>	

			<Modal
				open={openModal}
				onClose={(e) => { setOpenModal(false) }}
			>
				<div><ModalEditUser userId={id} reload={true} onClose={(e) => { setOpenModal(false) }} /></div>
			</Modal>
		</Box>
	);
	else 
	return (
		<Box className={classes.article}>

		{!avatar.length ? (
			<Avatar className={classes.articleAvatar}>
				{name.split(' ')[0][0].toUpperCase() + name.split(' ')[1][0].toUpperCase()}
			</Avatar>
		) : (
			<Avatar
				className={classes.articleAvatar}
				alt={name}
				src={avatar}
			/>
		)}


		<Typography 
			variant="h5" 
			component="h5"
			className={classes.articleId}
		>
			<span>ID</span> {/* id TODO make pretty*/} 
		</Typography>

		<Typography 
			variant="h5" 
			component="h5"
			className={classes.articleName}
		>
			<span>Nombre de usuario</span>

			{name}
		</Typography>
		
		<Typography 
			variant="h5" 
			component="h5"
			className={classes.articleEmail}
		>
			<span>Correo electrónico</span>

			{email}
		</Typography>
		

		<Box className={classes.articleActions}>
			<Button 
				variant="text" 
				className={classes.articleButton}
				onClick={(e) => { setOpenModal(!openModal) }}
			>
				Editar perfil

				<i className="ico-pen"></i>
			</Button>
		</Box>	

		<Modal
			open={openModal}
			onClose={(e) => { setOpenModal(false) }}
		>
			<div><ModalEditUser userId={id} reload={true} onClose={(e) => { setOpenModal(false) }} showTeacherCheckbox={true} /></div>
		</Modal>
	</Box>

	);
};

export default ArticleAlt;