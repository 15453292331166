/**
 * External dependencies.
 */
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({ children, modifier }) => {
	return (
		<Link
			to={"#"/* (modifier) ? "/circles" : "/" */}
			className={ 'logo ' + modifier}
		>
			{children}
		</Link>
	);
};

export default Logo;
