/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { NavLink } from 'react-router-dom';


const useStyles = makeStyles(theme => createStyles({
	nav: {
		padding: '0',
		fontSize: '24px',
		minHeight: '0',
		fontFamily: 'Poppins, sans-serif',
		fontWeight: '600',
		letterSpacing: '0.5px',
		textTransform: 'none',
		minWidth: '0 !important',
		[theme.breakpoints.down('md')]: {
			fontSize: '18px',
		}, 
		'& .is-active': {
			background: 'radial-gradient(100% 100% at 0% 50%, #0C55ED 0%, #00CCEE 100%)',
			backgroundClip: 'text',
			textFillColor: 'transparent',
			WebkitBackgroundClip: 'text',
		},
		'& a': {
			textDecoration: 'none',
			color: 'inherit',
			[theme.breakpoints.down('xs')]: {
				display: 'block',
			},
			'& + a': {
				marginLeft: '94px',
				[theme.breakpoints.down('md')]: {
					marginLeft: '50px',
				},
				[theme.breakpoints.down('xs')]: {
					margin: '20px 0 0'
				}
			},
			'&.active': {
				background: 'radial-gradient(100% 100% at 0% 50%, #0C55ED 0%, #00CCEE 100%)',
				backgroundClip: 'text',
				textFillColor: 'transparent',
				WebkitBackgroundClip: 'text',
			}
		}
	}
}));

const Nav = ( { modifier, activeLink, isSuperadmin } ) => {
	const classes = useStyles();

	return (
		<Box className={ classes.nav + ' ' + modifier }>
			{isSuperadmin && (<NavLink 
				to="/circles" 
				className={activeLink === 'másteres' ? 'is-active' : ''}
			>Másteres</NavLink>)}
			
			{isSuperadmin && (<NavLink 
				to="/users" 
				className={activeLink === 'users' ? 'is-active' : ''}
			>Usuarios</NavLink>)}
			
		</Box>
	);
};

export default Nav;