/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Modal from '@material-ui/core/Modal';
import Skeleton from '@mui/material/Skeleton';
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


/**
 * Internal dependencies.
 */
import WidgetEdit from 'components/widget-edit/widget-edit';
import ModalDeleteUser from 'components/modal-delete-user/modal-delete-user';
import { getProfileData, getRoles, requireAuthToken, requireUserID, submitAvatar } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 42px',
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalField: {
		marginBottom: '21px',
		'& input': {
			fontSize: '18px',
			fontWeight: '500',
			letterSpacing: '0.5px',
			height: '44px',
			border: 'none',
			background: '#F0F0F3',
			fontFamily: 'Poppins, sans-serif',
			color: '#323338',
			'&::-webkit-input-placeholder': {
				color: '#C2C8D0',
				opacity: '1',
				fontWeight: '500'
			},
			'&::-moz-placeholder': {
				color: '#C2C8D0',
				opacity: '1',
				fontWeight: '500'
			},
			'&:-moz-placeholder': {
				color: '#C2C8D0',
				opacity: '1',
				fontWeight: '500'
			},
			'&:-ms-input-placeholder': {
				color: '#C2C8D0',
				opacity: '1',
				fontWeight: '500'
			},
		}
	},
	modalCircle: {
		width: '200px',
		height: '200px',
		borderWidth: '2px',
		margin: '0 auto 29px',
		[theme.breakpoints.down('xs')]: {
			margin: '0 auto 29px'
		}
	},
	modalActions: {
		margin: '35px -30px 0',
		padding: '27px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			margin: '25px -30px 0',
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}

	},
	modalButtonDisabled: {
		opacity: '.3'
	},
	modalLink: {
		padding: '0',
		color: '#00CCEE',
		fontSize: '18px',
		letterSpacing: '0.5px',
		[theme.breakpoints.down('xs')]: {
			margin: '0 0 10px',
		}
	},
	modalLinks: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: '31px 0 0',
		[theme.breakpoints.down('xs')]:{
			display: 'block',
			margin: '20px 0 0',
		}
	},
	skeletonStyle: {
		minWidth: '100% !important'
	},
	subtitleStyle: {
		marginBottom: '1em'
	},
	errorMessage: {
		color: '#f50057'
	}
}));


const ModalEditUser = ( { userId, onClose, reload, modifier, showTeacherCheckbox } ) => {
	const classes = useStyles();
	const [ openInnerModal, setOpenInnerModal ] = useState(false);
	const [ name, setName ] = useState("");
	const [ email, setEmail ] = useState("");
	const [ password, setPassword ] = useState("");
	const [ isTeacher, setIsTeacher ] = useState(false);
	const [ repeatPassword, setRepeatPassword ] = useState("");
	const [ image, setImage ] = useState("");
	const [ errorMessage, setErrorMessage ] = useState("");
	const [ disabled, setDisabled ] = useState(false);
	const [ refreshOnClose, setRefreshOnClose ] = useState(false);


	// Load profile data
	useEffect(async () => {
		const checkedValue = await getRoles(userId);
		if (checkedValue)
			setIsTeacher(Boolean(checkedValue.length));

		const data = await getProfileData(userId);

		setName(data.username);
		setEmail(data.email);
		setImage(data.picture);

		console.log(data);
	}, []);

	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} disabled={disabled} onClick={e => {console.log(refreshOnClose);if (refreshOnClose) { window.location.reload();} onClose()}}>
					<i className="ico-x"></i>
				</button>

				<Typography 
					variant="h4" 
					component="h4"
					className={classes.modalTitle}
				>Editar perfil</Typography>
			</Box>

			<Box className={classes.modalBody}>
				<WidgetEdit 
					modifier={classes.modalCircle}	
					image={image}
				/>



				{(name) ? <Input id="username-field" disabled={disabled} placeholder="Nombre de usuario" defaultValue={name} className={classes.modalField}/> : <Skeleton animation='wave' variant="text" className={classes.skeletonStyle}><Input id="username-field" defaultValue={name} className={classes.modalField} /></Skeleton>}
				{(email) ? <Input id="email-field" disabled={disabled} defaultValue={email} placeholder="Email" className={classes.modalField}/> : <Skeleton animation='wave' variant="text" className={classes.skeletonStyle}><Input id="email-field" defaultValue={name} className={classes.modalField} /></Skeleton>}
				{(email) ? <Input id="password-field" type='password' disabled={disabled} placeholder="Nueva contraseña" className={classes.modalField} onChange={(e) => {setPassword(e.target.value)}}/> : <Skeleton animation='wave' variant="text" className={classes.skeletonStyle}><Input id="password-field" defaultValue={name} className={classes.modalField} /></Skeleton>}
				<Collapse in={password}><Input id="repeat-password-field" type='password' disabled={disabled} placeholder="Repetir contraseña" className={classes.modalField} onChange={(e) => {setRepeatPassword(e.target.value)}}/></Collapse>
				{(showTeacherCheckbox) && ((email) ? <FormControlLabel control={<Checkbox id='checkbox-teacher' defaultChecked={isTeacher}/>} label="Es profesor" /> : <Skeleton animation='wave' className={classes.skeletonStyle}><FormControlLabel control={<Checkbox id='checkbox-teacher' checked={isTeacher}/>} label="Es profesor" /></Skeleton>)}

				<Typography variant="subtitle1" className={classes.errorMessage}>{errorMessage}</Typography>

				<Box className={classes.modalLinks}>
					{/* <Button TODO implement
						variant="text" 
						className={classes.modalLink}
					>Reenviar código</Button> */}

					<Button 
						variant="text" 
						className={classes.modalLink}
						onClick={(e) => { setOpenInnerModal(true) }}
					>Eliminar usuario</Button>
				</Box>
			</Box>

			<Box className={classes.modalActions}>
				<Button 
					variant="outlined" 
					color="secondary"
					className={classes.modalButton}
					disabled={disabled}
					onClick={e => {console.log(refreshOnClose);if (refreshOnClose) { window.location.reload();} onClose()}}
				>Cancelar</Button>
				
				<Button 
					variant="contained" 
					color="secondary"
					className={classes.modalButton}
					disabled={disabled}
					onClick={(e) => { 
						setDisabled(true)
						submitChanges();

						async function submitChanges() {
							setErrorMessage("");

							const authToken = await requireAuthToken(false, true);

							const username_field = document.getElementById("username-field").value;
							const email_field = document.getElementById("email-field").value;
							const isTeacher_field = showTeacherCheckbox ? document.getElementById("checkbox-teacher").checked : isTeacher;

							if (password && password != repeatPassword) {
								setDisabled(false);
								setErrorMessage("Las contraseñas no coinciden")
								throw new Error(`Las contraseñas no coinciden`);
							} else {
								console.log('Las contraseñas coinciden!');
							}

							// Submit username
							if (name !== username_field) {
								const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user/${userId}`, {
									method: "POST",
									body: JSON.stringify({
										Username: username_field
									}),
									headers: {
										"Content-type": "application/json; charset=UTF-8",
										"Authorization": `Bearer ${authToken}`
									}
								});


								const data = await response.json();

								//console.log(data);

								if ("Error" in data) {
									setDisabled(false);
									setErrorMessage(data.Error.Message);
									throw new Error(`Error editing user: ${data.Error.Message}`);
								}
							}

							// Submit email
							if (email !== email_field) {
								const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user/${userId}`, {
									method: "POST",
									body: JSON.stringify({
										Email: email_field
									}),
									headers: {
										"Content-type": "application/json; charset=UTF-8",
										"Authorization": `Bearer ${authToken}`
									}
								});


								const data = await response.json();

								//console.log(data);

								if ("Error" in data) {
									setDisabled(false);
									setErrorMessage(data.Error.Message);
									setRefreshOnClose(true);
									console.log(refreshOnClose);
									throw new Error(`Error editing user: ${data.Error.Message}`);
								}
							}

							// Submit password
							if (password) {
								const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user/${userId}`, {
									method: "POST",
									body: JSON.stringify({
										Password: password
									}),
									headers: {
										"Content-type": "application/json; charset=UTF-8",
										"Authorization": `Bearer ${authToken}`
									}
								});


								const data = await response.json();

								//console.log(data);

								if ("Error" in data) {
									setDisabled(false);
									setErrorMessage(data.Error.Message);
									setRefreshOnClose(true);
									throw new Error(`Error editing user: ${data.Error.Message}`);
								}
							}

							// Submit role
							if (showTeacherCheckbox && isTeacher_field != isTeacher) {
								const newRole = isTeacher_field ? process.env.REACT_APP_AUTH0_ADMIN_ROLEID : "";
								const newCreator = isTeacher_field ? userId : await requireUserID(true);
								
								const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user/${userId}`, {
									method: "POST",
									body: JSON.stringify({
										RoleId: newRole,
										App_Metadata: {
											ShowAdminIcon: isTeacher_field,
											Creator: newCreator
										}
									}),
									headers: {
										"Content-type": "application/json; charset=UTF-8",
										"Authorization": `Bearer ${authToken}`
									}
								});


								const data = await response.json();

								//console.log(data);

								if ("Error" in data) {
									setDisabled(false);
									setErrorMessage(data.Error.Message);
									setRefreshOnClose(true);
									throw new Error(`Error editing user: ${data.Error.Message}`);
								}
							}

							if (reload) window.location.reload();

							onClose();
						}

					 }}
				>Guardar</Button>
			</Box>

			<Modal
				open={openInnerModal}
				onClose={(e) => { setOpenInnerModal(false) }}
			>
				<div><ModalDeleteUser username={name} userId={userId} onClose={(e) => { setOpenInnerModal(false) }}/></div>
			</Modal>
		</Box>
	);
};

export default ModalEditUser;

async function fetchAvatar(userId, setAvatar) {
	const authToken = await requireAuthToken(false, true);

	const responseAvatar = await fetch(`${process.env.REACT_APP_ENDPOINT}/avatar/${userId}`, {
		// Adding method type
		method: "GET",

		// Adding headers to the request
		headers: {
			"Accept": "application/json; charset=UTF-8",
			"Authorization": `Bearer ${authToken}`
		}
	});

	if (!responseAvatar.ok) {
		throw new Error(`HTTP error! status: ${responseAvatar.status}`); // handle errors
	}

	const dataAvatar = await responseAvatar.json();

	if ("Error" in dataAvatar) {
		throw new Error(`Error getting avatar: ${dataAvatar.Error.Message}`); // handle errors
	}
	await setAvatar(dataAvatar.DownloadUrl);

	return dataAvatar.DownloadUrl;
}
